export const collections = {
  "index": {
    "name": "index",
    "pascalName": "Index",
    "tableName": "_content_index",
    "source": [
      {
        "_resolved": true,
        "prefix": "/",
        "include": "index.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "hero": "json",
      "logos": "json",
      "features": "json",
      "foundation": "json",
      "modules": "json",
      "testimonial": "json",
      "deploy": "json",
      "contributors": "json",
      "stats": "json",
      "support": "json",
      "sponsors": "json"
    },
    "schema": {
      "$ref": "#/definitions/index",
      "definitions": {
        "index": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "hero": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                },
                "cta": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "to": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "to",
                    "icon"
                  ],
                  "additionalProperties": false
                },
                "tabs": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "title": {
                        "type": "string"
                      },
                      "icon": {
                        "type": "string"
                      },
                      "content": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "title",
                      "icon",
                      "content"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "cta",
                "tabs"
              ],
              "additionalProperties": false
            },
            "logos": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "companies": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "light": {
                        "editor": {
                          "input": "media"
                        },
                        "type": "string"
                      },
                      "dark": {
                        "editor": {
                          "input": "media"
                        },
                        "type": "string"
                      },
                      "width": {
                        "type": "number"
                      },
                      "height": {
                        "type": "number"
                      },
                      "alt": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "light",
                      "dark"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "companies"
              ],
              "additionalProperties": false
            },
            "features": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                },
                "links": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "icon": {
                        "type": "string"
                      },
                      "trailingIcon": {
                        "type": "string"
                      },
                      "to": {
                        "type": "string"
                      },
                      "color": {
                        "type": "string",
                        "enum": [
                          "primary",
                          "neutral",
                          "success",
                          "warning",
                          "error",
                          "info"
                        ]
                      },
                      "size": {
                        "type": "string",
                        "enum": [
                          "xs",
                          "sm",
                          "md",
                          "lg",
                          "xl"
                        ]
                      },
                      "variant": {
                        "type": "string",
                        "enum": [
                          "solid",
                          "outline",
                          "subtle",
                          "soft",
                          "ghost",
                          "link"
                        ]
                      },
                      "id": {
                        "type": "string"
                      },
                      "target": {
                        "type": "string",
                        "enum": [
                          "_blank",
                          "_self"
                        ]
                      }
                    },
                    "required": [
                      "label"
                    ],
                    "additionalProperties": false
                  }
                },
                "features": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "title": {
                        "type": "string"
                      },
                      "description": {
                        "type": "string"
                      },
                      "icon": {
                        "editor": {
                          "input": "icon"
                        },
                        "type": "string"
                      },
                      "to": {
                        "type": "string"
                      },
                      "target": {
                        "type": "string",
                        "enum": [
                          "_blank",
                          "_self"
                        ]
                      },
                      "soon": {
                        "type": "boolean"
                      }
                    },
                    "required": [
                      "title",
                      "description",
                      "icon"
                    ],
                    "additionalProperties": false
                  }
                },
                "image": {
                  "$ref": "#/definitions/index/properties/logos/properties/companies/items"
                },
                "cta": {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "label": {
                      "type": "string"
                    },
                    "to": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "label",
                    "to",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              },
              "required": [
                "title",
                "description",
                "links",
                "features",
                "image"
              ],
              "additionalProperties": false
            },
            "foundation": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/index/properties/features/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/index/properties/features/properties/description"
                },
                "links": {
                  "$ref": "#/definitions/index/properties/features/properties/links"
                },
                "features": {
                  "$ref": "#/definitions/index/properties/features/properties/features"
                },
                "image": {
                  "$ref": "#/definitions/index/properties/logos/properties/companies/items"
                },
                "cta": {
                  "$ref": "#/definitions/index/properties/features/properties/cta"
                },
                "items": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "id": {
                        "type": "string"
                      },
                      "title": {
                        "type": "string"
                      },
                      "description": {
                        "type": "string"
                      },
                      "logo": {
                        "type": "string"
                      },
                      "color": {
                        "type": "string"
                      },
                      "gradient": {
                        "type": "string"
                      },
                      "link": {
                        "type": "object",
                        "properties": {
                          "label": {
                            "$ref": "#/definitions/index/properties/hero/properties/cta/properties/label"
                          },
                          "to": {
                            "$ref": "#/definitions/index/properties/hero/properties/cta/properties/to"
                          },
                          "icon": {
                            "type": "string"
                          }
                        },
                        "required": [
                          "label",
                          "to"
                        ],
                        "additionalProperties": false
                      }
                    },
                    "required": [
                      "id",
                      "title",
                      "description",
                      "logo",
                      "color",
                      "gradient",
                      "link"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "links",
                "features",
                "image",
                "items"
              ],
              "additionalProperties": false
            },
            "modules": {
              "$ref": "#/definitions/index/properties/features"
            },
            "testimonial": {
              "type": "object",
              "properties": {
                "quote": {
                  "type": "string"
                },
                "author": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "username": {
                      "type": "string"
                    },
                    "twitter": {
                      "type": "string"
                    },
                    "to": {
                      "type": "string"
                    },
                    "avatar": {
                      "type": "object",
                      "properties": {
                        "src": {
                          "type": "string"
                        },
                        "alt": {
                          "type": "string"
                        },
                        "width": {
                          "type": "number"
                        },
                        "height": {
                          "type": "number"
                        }
                      },
                      "required": [
                        "src",
                        "alt"
                      ],
                      "additionalProperties": false
                    }
                  },
                  "required": [
                    "name"
                  ],
                  "additionalProperties": false
                }
              },
              "required": [
                "quote",
                "author"
              ],
              "additionalProperties": false
            },
            "deploy": {
              "$ref": "#/definitions/index/properties/features"
            },
            "contributors": {
              "$ref": "#/definitions/index/properties/features"
            },
            "stats": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/index/properties/features/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/index/properties/features/properties/description"
                },
                "links": {
                  "$ref": "#/definitions/index/properties/features/properties/links"
                },
                "features": {
                  "$ref": "#/definitions/index/properties/features/properties/features"
                },
                "image": {
                  "$ref": "#/definitions/index/properties/logos/properties/companies/items"
                },
                "cta": {
                  "$ref": "#/definitions/index/properties/features/properties/links/items"
                },
                "community": {
                  "type": "object",
                  "properties": {
                    "title": {
                      "$ref": "#/definitions/index/properties/features/properties/title"
                    },
                    "description": {
                      "$ref": "#/definitions/index/properties/features/properties/description"
                    }
                  },
                  "required": [
                    "title",
                    "description"
                  ],
                  "additionalProperties": false
                },
                "x": {
                  "type": "number"
                },
                "discord": {
                  "type": "string"
                }
              },
              "required": [
                "title",
                "description",
                "links",
                "features",
                "image",
                "cta",
                "community",
                "x",
                "discord"
              ],
              "additionalProperties": false
            },
            "support": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/index/properties/features/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/index/properties/features/properties/description"
                },
                "links": {
                  "$ref": "#/definitions/index/properties/features/properties/links"
                },
                "features": {
                  "$ref": "#/definitions/index/properties/features/properties/features"
                },
                "image": {
                  "$ref": "#/definitions/index/properties/logos/properties/companies/items"
                },
                "cta": {
                  "$ref": "#/definitions/index/properties/features/properties/cta"
                },
                "companies": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "src": {
                        "$ref": "#/definitions/index/properties/testimonial/properties/author/properties/avatar/properties/src"
                      },
                      "alt": {
                        "$ref": "#/definitions/index/properties/testimonial/properties/author/properties/avatar/properties/alt"
                      }
                    },
                    "required": [
                      "src",
                      "alt"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "links",
                "features",
                "image",
                "companies"
              ],
              "additionalProperties": false
            },
            "sponsors": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/index/properties/features/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/index/properties/features/properties/description"
                },
                "links": {
                  "$ref": "#/definitions/index/properties/features/properties/links"
                },
                "features": {
                  "$ref": "#/definitions/index/properties/features/properties/features"
                },
                "image": {
                  "$ref": "#/definitions/index/properties/logos/properties/companies/items"
                },
                "cta": {
                  "$ref": "#/definitions/index/properties/features/properties/links/items"
                }
              },
              "required": [
                "title",
                "description",
                "links",
                "features",
                "image",
                "cta"
              ],
              "additionalProperties": false
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "hero",
            "logos",
            "features",
            "foundation",
            "modules",
            "testimonial",
            "deploy",
            "contributors",
            "stats",
            "support",
            "sponsors"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_index (id TEXT PRIMARY KEY, \"contributors\" TEXT, \"deploy\" TEXT, \"extension\" VARCHAR, \"features\" TEXT, \"foundation\" TEXT, \"hero\" TEXT, \"logos\" TEXT, \"meta\" TEXT, \"modules\" TEXT, \"sponsors\" TEXT, \"stats\" TEXT, \"stem\" VARCHAR, \"support\" TEXT, \"testimonial\" TEXT, \"__hash__\" TEXT UNIQUE);"
  },
  "docs": {
    "name": "docs",
    "pascalName": "Docs",
    "tableName": "_content_docs",
    "source": [],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "titleTemplate": "string",
      "links": "json"
    },
    "schema": {
      "$ref": "#/definitions/docs",
      "definitions": {
        "docs": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "titleTemplate": {
              "type": "string"
            },
            "links": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "label": {
                    "type": "string"
                  },
                  "icon": {
                    "type": "string"
                  },
                  "trailingIcon": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "color": {
                    "type": "string",
                    "enum": [
                      "primary",
                      "neutral",
                      "success",
                      "warning",
                      "error",
                      "info"
                    ]
                  },
                  "size": {
                    "type": "string",
                    "enum": [
                      "xs",
                      "sm",
                      "md",
                      "lg",
                      "xl"
                    ]
                  },
                  "variant": {
                    "type": "string",
                    "enum": [
                      "solid",
                      "outline",
                      "subtle",
                      "soft",
                      "ghost",
                      "link"
                    ]
                  },
                  "id": {
                    "type": "string"
                  },
                  "target": {
                    "type": "string",
                    "enum": [
                      "_blank",
                      "_self"
                    ]
                  }
                },
                "required": [
                  "label"
                ],
                "additionalProperties": false
              }
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body",
            "links"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_docs (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"description\" VARCHAR, \"extension\" VARCHAR, \"links\" TEXT, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"titleTemplate\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "blog": {
    "name": "blog",
    "pascalName": "Blog",
    "tableName": "_content_blog",
    "source": [
      {
        "_resolved": true,
        "prefix": "/blog",
        "include": "blog/*",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "authors": "json",
      "date": "string",
      "draft": "boolean",
      "category": "string",
      "tags": "json"
    },
    "schema": {
      "$ref": "#/definitions/blog",
      "definitions": {
        "blog": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "image": {
              "editor": {
                "input": "media"
              },
              "type": "string"
            },
            "authors": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "name": {
                    "type": "string"
                  },
                  "description": {
                    "type": "string"
                  },
                  "username": {
                    "type": "string"
                  },
                  "twitter": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "avatar": {
                    "type": "object",
                    "properties": {
                      "src": {
                        "type": "string"
                      },
                      "alt": {
                        "type": "string"
                      },
                      "width": {
                        "type": "number"
                      },
                      "height": {
                        "type": "number"
                      }
                    },
                    "required": [
                      "src",
                      "alt"
                    ],
                    "additionalProperties": false
                  }
                },
                "required": [
                  "name"
                ],
                "additionalProperties": false
              }
            },
            "date": {
              "type": "string",
              "format": "date"
            },
            "draft": {
              "type": "boolean"
            },
            "category": {
              "type": "string",
              "enum": [
                "Release",
                "Tutorial",
                "Announcement",
                "Article"
              ]
            },
            "tags": {
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body",
            "image",
            "authors",
            "date",
            "category",
            "tags"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_blog (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"authors\" TEXT, \"body\" TEXT, \"category\" VARCHAR, \"date\" VARCHAR, \"description\" VARCHAR, \"draft\" BOOLEAN, \"extension\" VARCHAR, \"image\" VARCHAR, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"tags\" TEXT, \"__hash__\" TEXT UNIQUE);"
  },
  "landing": {
    "name": "landing",
    "pascalName": "Landing",
    "tableName": "_content_landing",
    "source": [
      {
        "_resolved": true,
        "prefix": "/",
        "include": "index.md",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "blog.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "modules.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "deploy.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "templates.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "showcase.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "video-courses.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "enterprise/sponsors.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "enterprise/agencies.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "newsletter.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      },
      {
        "_resolved": true,
        "prefix": "/",
        "include": "enterprise/jobs.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json"
    },
    "schema": {
      "$ref": "#/definitions/landing",
      "definitions": {
        "landing": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "image": {
              "type": "object",
              "properties": {
                "light": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "dark": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "width": {
                  "type": "number"
                },
                "height": {
                  "type": "number"
                },
                "alt": {
                  "type": "string"
                }
              },
              "required": [
                "light",
                "dark"
              ],
              "additionalProperties": false
            },
            "head": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            },
            "headline": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "editor": {
                    "input": "icon"
                  },
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            },
            "links": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "label": {
                    "type": "string"
                  },
                  "icon": {
                    "type": "string"
                  },
                  "trailingIcon": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "color": {
                    "type": "string",
                    "enum": [
                      "primary",
                      "neutral",
                      "success",
                      "warning",
                      "error",
                      "info"
                    ]
                  },
                  "size": {
                    "type": "string",
                    "enum": [
                      "xs",
                      "sm",
                      "md",
                      "lg",
                      "xl"
                    ]
                  },
                  "variant": {
                    "type": "string",
                    "enum": [
                      "solid",
                      "outline",
                      "subtle",
                      "soft",
                      "ghost",
                      "link"
                    ]
                  },
                  "id": {
                    "type": "string"
                  },
                  "target": {
                    "type": "string",
                    "enum": [
                      "_blank",
                      "_self"
                    ]
                  }
                },
                "required": [
                  "label"
                ],
                "additionalProperties": false
              }
            },
            "cta": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_landing (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"cta\" TEXT, \"description\" VARCHAR, \"extension\" VARCHAR, \"head\" TEXT, \"headline\" TEXT, \"image\" TEXT, \"links\" TEXT, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "deploy": {
    "name": "deploy",
    "pascalName": "Deploy",
    "tableName": "_content_deploy",
    "source": [
      {
        "_resolved": true,
        "prefix": "/deploy",
        "include": "deploy/*",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "componentImg": "string",
      "logoSrc": "string",
      "featured": "boolean",
      "logoIcon": "string",
      "category": "string",
      "nitroPreset": "string",
      "website": "string"
    },
    "schema": {
      "$ref": "#/definitions/deploy",
      "definitions": {
        "deploy": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "componentImg": {
              "type": "string"
            },
            "logoSrc": {
              "type": "string"
            },
            "featured": {
              "type": "boolean"
            },
            "logoIcon": {
              "type": "string"
            },
            "category": {
              "type": "string"
            },
            "nitroPreset": {
              "type": "string"
            },
            "website": {
              "type": "string",
              "format": "uri"
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body",
            "componentImg",
            "logoSrc",
            "featured",
            "logoIcon",
            "category",
            "nitroPreset",
            "website"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_deploy (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"category\" VARCHAR, \"componentImg\" VARCHAR, \"description\" VARCHAR, \"extension\" VARCHAR, \"featured\" BOOLEAN, \"logoIcon\" VARCHAR, \"logoSrc\" VARCHAR, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"nitroPreset\" VARCHAR, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"website\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "support": {
    "name": "support",
    "pascalName": "Support",
    "tableName": "_content_support",
    "source": [
      {
        "_resolved": true,
        "prefix": "/",
        "include": "enterprise/support.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "title": "string",
      "description": "string",
      "hero": "json",
      "logos": "json",
      "service": "json",
      "expertise": "json",
      "testimonials": "json",
      "project": "json",
      "form": "json"
    },
    "schema": {
      "$ref": "#/definitions/support",
      "definitions": {
        "support": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "hero": {
              "type": "object",
              "properties": {
                "links": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "icon": {
                        "type": "string"
                      },
                      "trailingIcon": {
                        "type": "string"
                      },
                      "to": {
                        "type": "string"
                      },
                      "color": {
                        "type": "string",
                        "enum": [
                          "primary",
                          "neutral",
                          "success",
                          "warning",
                          "error",
                          "info"
                        ]
                      },
                      "size": {
                        "type": "string",
                        "enum": [
                          "xs",
                          "sm",
                          "md",
                          "lg",
                          "xl"
                        ]
                      },
                      "variant": {
                        "type": "string",
                        "enum": [
                          "solid",
                          "outline",
                          "subtle",
                          "soft",
                          "ghost",
                          "link"
                        ]
                      },
                      "id": {
                        "type": "string"
                      },
                      "target": {
                        "type": "string",
                        "enum": [
                          "_blank",
                          "_self"
                        ]
                      }
                    },
                    "required": [
                      "label"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "links"
              ],
              "additionalProperties": false
            },
            "logos": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "light": {
                    "editor": {
                      "input": "media"
                    },
                    "type": "string"
                  },
                  "dark": {
                    "editor": {
                      "input": "media"
                    },
                    "type": "string"
                  },
                  "alt": {
                    "type": "string"
                  },
                  "width": {
                    "type": "string"
                  },
                  "height": {
                    "type": "string"
                  }
                },
                "required": [
                  "light",
                  "dark",
                  "alt",
                  "width",
                  "height"
                ],
                "additionalProperties": false
              }
            },
            "service": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                },
                "services": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "title": {
                        "$ref": "#/definitions/support/properties/service/properties/title"
                      },
                      "description": {
                        "$ref": "#/definitions/support/properties/service/properties/description"
                      },
                      "icon": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "title",
                      "description",
                      "icon"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "services"
              ],
              "additionalProperties": false
            },
            "expertise": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/support/properties/service/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/support/properties/service/properties/description"
                },
                "logos": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "src": {
                        "type": "string"
                      },
                      "alt": {
                        "type": "string"
                      },
                      "width": {
                        "type": "number"
                      },
                      "height": {
                        "type": "number"
                      },
                      "color": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "src",
                      "alt",
                      "color"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "logos"
              ],
              "additionalProperties": false
            },
            "testimonials": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/support/properties/service/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/support/properties/service/properties/description"
                },
                "items": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "quote": {
                        "type": "string"
                      },
                      "author": {
                        "type": "string"
                      },
                      "job": {
                        "type": "string"
                      },
                      "logo": {
                        "type": "object",
                        "properties": {
                          "light": {
                            "editor": {
                              "input": "media"
                            },
                            "$ref": "#/definitions/support/properties/logos/items/properties/light"
                          },
                          "dark": {
                            "editor": {
                              "input": "media"
                            },
                            "$ref": "#/definitions/support/properties/logos/items/properties/dark"
                          },
                          "width": {
                            "type": "number"
                          },
                          "height": {
                            "type": "number"
                          },
                          "alt": {
                            "type": "string"
                          }
                        },
                        "required": [
                          "light",
                          "dark"
                        ],
                        "additionalProperties": false
                      },
                      "achievements": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "properties": {
                            "label": {
                              "type": "string"
                            },
                            "color": {
                              "type": "string",
                              "enum": [
                                "success",
                                "warning",
                                "error",
                                "info",
                                "neutral",
                                "important"
                              ]
                            }
                          },
                          "required": [
                            "label",
                            "color"
                          ],
                          "additionalProperties": false
                        }
                      },
                      "width": {
                        "type": "number"
                      },
                      "height": {
                        "type": "number"
                      }
                    },
                    "required": [
                      "quote",
                      "author",
                      "job",
                      "logo",
                      "achievements",
                      "width",
                      "height"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "items"
              ],
              "additionalProperties": false
            },
            "project": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/support/properties/service/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/support/properties/service/properties/description"
                },
                "steps": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "title": {
                        "$ref": "#/definitions/support/properties/service/properties/title"
                      },
                      "description": {
                        "$ref": "#/definitions/support/properties/service/properties/description"
                      },
                      "number": {
                        "type": "number"
                      }
                    },
                    "required": [
                      "title",
                      "description",
                      "number"
                    ],
                    "additionalProperties": false
                  }
                }
              },
              "required": [
                "title",
                "description",
                "steps"
              ],
              "additionalProperties": false
            },
            "form": {
              "type": "object",
              "properties": {
                "title": {
                  "$ref": "#/definitions/support/properties/service/properties/title"
                },
                "description": {
                  "$ref": "#/definitions/support/properties/service/properties/description"
                },
                "name": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "placeholder": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "placeholder"
                  ],
                  "additionalProperties": false
                },
                "email": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "placeholder": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "placeholder"
                  ],
                  "additionalProperties": false
                },
                "company": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "placeholder": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "placeholder"
                  ],
                  "additionalProperties": false
                },
                "link": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "placeholder": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "placeholder"
                  ],
                  "additionalProperties": false
                },
                "body": {
                  "type": "object",
                  "properties": {
                    "label": {
                      "type": "string"
                    },
                    "placeholder": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "label",
                    "placeholder"
                  ],
                  "additionalProperties": false
                },
                "info": {
                  "type": "string"
                },
                "button": {
                  "$ref": "#/definitions/support/properties/hero/properties/links/items"
                }
              },
              "required": [
                "title",
                "description",
                "name",
                "email",
                "company",
                "link",
                "body",
                "info",
                "button"
              ],
              "additionalProperties": false
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "title",
            "description",
            "hero",
            "logos",
            "service",
            "expertise",
            "testimonials",
            "project",
            "form"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_support (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"description\" VARCHAR, \"expertise\" TEXT, \"extension\" VARCHAR, \"form\" TEXT, \"hero\" TEXT, \"logos\" TEXT, \"meta\" TEXT, \"project\" TEXT, \"service\" TEXT, \"stem\" VARCHAR, \"testimonials\" TEXT, \"__hash__\" TEXT UNIQUE);"
  },
  "agencies": {
    "name": "agencies",
    "pascalName": "Agencies",
    "tableName": "_content_agencies",
    "source": [
      {
        "_resolved": true,
        "prefix": "/enterprise/agencies",
        "include": "enterprise/agencies/*.md",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "logo": "json",
      "logoFull": "string",
      "link": "string",
      "services": "json",
      "resources": "json",
      "emailAddress": "string",
      "phoneNumber": "string",
      "x": "string",
      "github": "string",
      "linkedin": "string",
      "instagram": "string",
      "color": "json",
      "regions": "json",
      "location": "string"
    },
    "schema": {
      "$ref": "#/definitions/agencies",
      "definitions": {
        "agencies": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "logo": {
              "type": "object",
              "properties": {
                "light": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "dark": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "width": {
                  "type": "number"
                },
                "height": {
                  "type": "number"
                },
                "alt": {
                  "type": "string"
                }
              },
              "required": [
                "light",
                "dark"
              ],
              "additionalProperties": false
            },
            "logoFull": {
              "type": "string"
            },
            "link": {
              "type": "string",
              "format": "uri"
            },
            "services": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "resources": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "label": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "icon": {
                    "type": "string"
                  }
                },
                "required": [
                  "label",
                  "to"
                ],
                "additionalProperties": false
              }
            },
            "emailAddress": {
              "type": "string",
              "format": "email"
            },
            "phoneNumber": {
              "type": [
                "string",
                "null"
              ]
            },
            "x": {
              "type": "string"
            },
            "github": {
              "type": "string"
            },
            "linkedin": {
              "type": "string"
            },
            "instagram": {
              "type": "string"
            },
            "color": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "regions": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "location": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body",
            "logo",
            "link",
            "services",
            "regions",
            "location"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_agencies (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"color\" TEXT, \"description\" VARCHAR, \"emailAddress\" VARCHAR, \"extension\" VARCHAR, \"github\" VARCHAR, \"instagram\" VARCHAR, \"link\" VARCHAR, \"linkedin\" VARCHAR, \"location\" VARCHAR, \"logo\" TEXT, \"logoFull\" VARCHAR, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"phoneNumber\" VARCHAR NULL, \"regions\" TEXT, \"resources\" TEXT, \"seo\" TEXT DEFAULT '{}', \"services\" TEXT, \"stem\" VARCHAR, \"x\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "templates": {
    "name": "templates",
    "pascalName": "Templates",
    "tableName": "_content_templates",
    "source": [
      {
        "_resolved": true,
        "prefix": "/templates",
        "include": "templates/*",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "name": "string",
      "slug": "string",
      "description": "string",
      "repo": "string",
      "demo": "string",
      "purchase": "string",
      "featured": "boolean",
      "badge": "string",
      "screenshotUrl": "string",
      "screenshotOptions": "json"
    },
    "schema": {
      "$ref": "#/definitions/templates",
      "definitions": {
        "templates": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "name": {
              "type": "string"
            },
            "slug": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "repo": {
              "type": "string"
            },
            "demo": {
              "type": "string",
              "format": "uri"
            },
            "purchase": {
              "type": "string",
              "format": "uri"
            },
            "featured": {
              "type": "boolean"
            },
            "badge": {
              "type": "string",
              "enum": [
                "Premium",
                "Freemium",
                "Free"
              ]
            },
            "screenshotUrl": {
              "type": "string",
              "format": "uri"
            },
            "screenshotOptions": {
              "type": "object",
              "properties": {
                "delay": {
                  "type": "number"
                }
              },
              "required": [
                "delay"
              ],
              "additionalProperties": false
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "name",
            "slug",
            "description",
            "demo"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_templates (id TEXT PRIMARY KEY, \"badge\" VARCHAR, \"demo\" VARCHAR, \"description\" VARCHAR, \"extension\" VARCHAR, \"featured\" BOOLEAN, \"meta\" TEXT, \"name\" VARCHAR, \"purchase\" VARCHAR, \"repo\" VARCHAR, \"screenshotOptions\" TEXT, \"screenshotUrl\" VARCHAR, \"slug\" VARCHAR, \"stem\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "videoCourses": {
    "name": "videoCourses",
    "pascalName": "VideoCourses",
    "tableName": "_content_videoCourses",
    "source": [
      {
        "_resolved": true,
        "prefix": "/video-courses",
        "include": "video-courses/*",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "name": "string",
      "slug": "string",
      "description": "string",
      "url": "string",
      "badge": "string",
      "screenshotOptions": "json",
      "sponsor": "boolean"
    },
    "schema": {
      "$ref": "#/definitions/videoCourses",
      "definitions": {
        "videoCourses": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "name": {
              "type": "string"
            },
            "slug": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "url": {
              "type": "string",
              "format": "uri"
            },
            "badge": {
              "type": "string",
              "enum": [
                "Premium",
                "Free"
              ]
            },
            "screenshotOptions": {
              "type": "object",
              "properties": {
                "delay": {
                  "type": "number"
                },
                "removeElements": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                }
              },
              "required": [
                "delay"
              ],
              "additionalProperties": false
            },
            "sponsor": {
              "type": "boolean"
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "name",
            "slug",
            "description",
            "url"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_videoCourses (id TEXT PRIMARY KEY, \"badge\" VARCHAR, \"description\" VARCHAR, \"extension\" VARCHAR, \"meta\" TEXT, \"name\" VARCHAR, \"screenshotOptions\" TEXT, \"slug\" VARCHAR, \"sponsor\" BOOLEAN, \"stem\" VARCHAR, \"url\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "designKit": {
    "name": "designKit",
    "pascalName": "DesignKit",
    "tableName": "_content_designKit",
    "source": [
      {
        "_resolved": true,
        "prefix": "/",
        "include": "design-kit.md",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json"
    },
    "schema": {
      "$ref": "#/definitions/designKit",
      "definitions": {
        "designKit": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "image": {
              "type": "object",
              "properties": {
                "light": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "dark": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "width": {
                  "type": "number"
                },
                "height": {
                  "type": "number"
                },
                "alt": {
                  "type": "string"
                }
              },
              "required": [
                "light",
                "dark"
              ],
              "additionalProperties": false
            },
            "head": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            },
            "headline": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "editor": {
                    "input": "icon"
                  },
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            },
            "links": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "label": {
                    "type": "string"
                  },
                  "icon": {
                    "type": "string"
                  },
                  "trailingIcon": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "color": {
                    "type": "string",
                    "enum": [
                      "primary",
                      "neutral",
                      "success",
                      "warning",
                      "error",
                      "info"
                    ]
                  },
                  "size": {
                    "type": "string",
                    "enum": [
                      "xs",
                      "sm",
                      "md",
                      "lg",
                      "xl"
                    ]
                  },
                  "variant": {
                    "type": "string",
                    "enum": [
                      "solid",
                      "outline",
                      "subtle",
                      "soft",
                      "ghost",
                      "link"
                    ]
                  },
                  "id": {
                    "type": "string"
                  },
                  "target": {
                    "type": "string",
                    "enum": [
                      "_blank",
                      "_self"
                    ]
                  }
                },
                "required": [
                  "label"
                ],
                "additionalProperties": false
              }
            },
            "cta": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_designKit (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"cta\" TEXT, \"description\" VARCHAR, \"extension\" VARCHAR, \"head\" TEXT, \"headline\" TEXT, \"image\" TEXT, \"links\" TEXT, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "team": {
    "name": "team",
    "pascalName": "Team",
    "tableName": "_content_team",
    "source": [
      {
        "_resolved": true,
        "prefix": "/",
        "include": "team.yml",
        "cwd": "/home/runner/work/nuxt.com/nuxt.com/content"
      }
    ],
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json",
      "users": "json"
    },
    "schema": {
      "$ref": "#/definitions/team",
      "definitions": {
        "team": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "path": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "description": {
              "type": "string"
            },
            "seo": {
              "allOf": [
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "object",
                  "additionalProperties": {}
                }
              ],
              "default": {}
            },
            "body": {
              "type": "object",
              "properties": {
                "type": {
                  "type": "string"
                },
                "children": {},
                "toc": {}
              },
              "required": [
                "type"
              ],
              "additionalProperties": false
            },
            "navigation": {
              "anyOf": [
                {
                  "type": "boolean"
                },
                {
                  "type": "object",
                  "properties": {
                    "title": {
                      "type": "string"
                    },
                    "description": {
                      "type": "string"
                    },
                    "icon": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "title",
                    "description",
                    "icon"
                  ],
                  "additionalProperties": false
                }
              ],
              "default": true
            },
            "image": {
              "type": "object",
              "properties": {
                "light": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "dark": {
                  "editor": {
                    "input": "media"
                  },
                  "type": "string"
                },
                "width": {
                  "type": "number"
                },
                "height": {
                  "type": "number"
                },
                "alt": {
                  "type": "string"
                }
              },
              "required": [
                "light",
                "dark"
              ],
              "additionalProperties": false
            },
            "head": {
              "type": "object",
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                }
              },
              "additionalProperties": false
            },
            "headline": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "editor": {
                    "input": "icon"
                  },
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            },
            "links": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "label": {
                    "type": "string"
                  },
                  "icon": {
                    "type": "string"
                  },
                  "trailingIcon": {
                    "type": "string"
                  },
                  "to": {
                    "type": "string"
                  },
                  "color": {
                    "type": "string",
                    "enum": [
                      "primary",
                      "neutral",
                      "success",
                      "warning",
                      "error",
                      "info"
                    ]
                  },
                  "size": {
                    "type": "string",
                    "enum": [
                      "xs",
                      "sm",
                      "md",
                      "lg",
                      "xl"
                    ]
                  },
                  "variant": {
                    "type": "string",
                    "enum": [
                      "solid",
                      "outline",
                      "subtle",
                      "soft",
                      "ghost",
                      "link"
                    ]
                  },
                  "id": {
                    "type": "string"
                  },
                  "target": {
                    "type": "string",
                    "enum": [
                      "_blank",
                      "_self"
                    ]
                  }
                },
                "required": [
                  "label"
                ],
                "additionalProperties": false
              }
            },
            "cta": {
              "type": "object",
              "properties": {
                "label": {
                  "type": "string"
                },
                "to": {
                  "type": "string"
                },
                "icon": {
                  "type": "string"
                }
              },
              "required": [
                "label",
                "to"
              ],
              "additionalProperties": false
            },
            "users": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "name": {
                    "type": "string"
                  },
                  "location": {
                    "type": "string"
                  },
                  "sponsor": {
                    "type": "string",
                    "format": "uri"
                  },
                  "avatar": {
                    "type": "object",
                    "properties": {
                      "src": {
                        "type": "string"
                      },
                      "alt": {
                        "type": "string"
                      },
                      "width": {
                        "type": "number"
                      },
                      "height": {
                        "type": "number"
                      }
                    },
                    "required": [
                      "src",
                      "alt"
                    ],
                    "additionalProperties": false
                  },
                  "links": {
                    "type": "array",
                    "items": {
                      "$ref": "#/definitions/team/properties/cta"
                    }
                  }
                },
                "required": [
                  "name",
                  "location",
                  "sponsor",
                  "avatar",
                  "links"
                ],
                "additionalProperties": false
              }
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "path",
            "title",
            "description",
            "body",
            "users"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_team (id TEXT PRIMARY KEY, \"title\" VARCHAR, \"body\" TEXT, \"cta\" TEXT, \"description\" VARCHAR, \"extension\" VARCHAR, \"head\" TEXT, \"headline\" TEXT, \"image\" TEXT, \"links\" TEXT, \"meta\" TEXT, \"navigation\" TEXT DEFAULT true, \"path\" VARCHAR, \"seo\" TEXT DEFAULT '{}', \"stem\" VARCHAR, \"users\" TEXT, \"__hash__\" TEXT UNIQUE);"
  },
  "info": {
    "name": "info",
    "pascalName": "Info",
    "tableName": "_content_info",
    "source": [],
    "type": "data",
    "fields": {},
    "schema": {
      "$ref": "#/definitions/info",
      "definitions": {
        "info": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "version": {
              "type": "string"
            },
            "structureVersion": {
              "type": "string"
            },
            "ready": {
              "type": "boolean"
            }
          },
          "required": [
            "id",
            "version",
            "structureVersion",
            "ready"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_info (id TEXT PRIMARY KEY, \"ready\" BOOLEAN, \"structureVersion\" VARCHAR, \"version\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  }
}
export const gitInfo = {
  "name": "nuxt.com",
  "owner": "nuxt",
  "url": "https://github.com/nuxt/nuxt.com"
}
export const appConfigSchema = {
  "properties": {
    "id": "#appConfig",
    "properties": {
      "icon": {
        "title": "Nuxt Icon",
        "description": "Configure Nuxt Icon module preferences.",
        "tags": [
          "@studioIcon material-symbols:star"
        ],
        "id": "#appConfig/icon",
        "properties": {
          "size": {
            "title": "Icon Size",
            "description": "Set the default icon size.",
            "tags": [
              "@studioIcon material-symbols:format-size-rounded"
            ],
            "tsType": "string | undefined",
            "id": "#appConfig/icon/size",
            "default": {},
            "type": "any"
          },
          "class": {
            "title": "CSS Class",
            "description": "Set the default CSS class.",
            "tags": [
              "@studioIcon material-symbols:css"
            ],
            "id": "#appConfig/icon/class",
            "default": "",
            "type": "string"
          },
          "attrs": {
            "title": "Default Attributes",
            "description": "Attributes applied to every icon component.\n\n@default { \"aria-hidden\": true }",
            "tags": [
              "@studioIcon material-symbols:settings"
            ],
            "tsType": "Record<string, string | number | boolean>",
            "id": "#appConfig/icon/attrs",
            "default": {
              "aria-hidden": true
            },
            "type": "object"
          },
          "mode": {
            "title": "Default Rendering Mode",
            "description": "Set the default rendering mode for the icon component",
            "enum": [
              "css",
              "svg"
            ],
            "tags": [
              "@studioIcon material-symbols:move-down-rounded"
            ],
            "id": "#appConfig/icon/mode",
            "default": "css",
            "type": "string"
          },
          "aliases": {
            "title": "Icon aliases",
            "description": "Define Icon aliases to update them easily without code changes.",
            "tags": [
              "@studioIcon material-symbols:star-rounded"
            ],
            "tsType": "{ [alias: string]: string }",
            "id": "#appConfig/icon/aliases",
            "default": {},
            "type": "object"
          },
          "cssSelectorPrefix": {
            "title": "CSS Selector Prefix",
            "description": "Set the default CSS selector prefix.",
            "tags": [
              "@studioIcon material-symbols:format-textdirection-l-to-r"
            ],
            "id": "#appConfig/icon/cssSelectorPrefix",
            "default": "i-",
            "type": "string"
          },
          "cssLayer": {
            "title": "CSS Layer Name",
            "description": "Set the default CSS `@layer` name.",
            "tags": [
              "@studioIcon material-symbols:layers"
            ],
            "tsType": "string | undefined",
            "id": "#appConfig/icon/cssLayer",
            "default": {},
            "type": "any"
          },
          "cssWherePseudo": {
            "title": "Use CSS `:where()` Pseudo Selector",
            "description": "Use CSS `:where()` pseudo selector to reduce specificity.",
            "tags": [
              "@studioIcon material-symbols:low-priority"
            ],
            "id": "#appConfig/icon/cssWherePseudo",
            "default": true,
            "type": "boolean"
          },
          "collections": {
            "title": "Icon Collections",
            "description": "List of known icon collections name. Used to resolve collection name ambiguity.\ne.g. `simple-icons-github` -> `simple-icons:github` instead of `simple:icons-github`\n\nWhen not provided, will use the full Iconify collection list.",
            "tags": [
              "@studioIcon material-symbols:format-list-bulleted"
            ],
            "tsType": "string[] | null",
            "id": "#appConfig/icon/collections",
            "default": null,
            "type": "any"
          },
          "customCollections": {
            "title": "Custom Icon Collections",
            "tags": [
              "@studioIcon material-symbols:format-list-bulleted"
            ],
            "tsType": "string[] | null",
            "id": "#appConfig/icon/customCollections",
            "default": null,
            "type": "any"
          },
          "provider": {
            "title": "Icon Provider",
            "description": "Provider to use for fetching icons\n\n- `server` - Fetch icons with a server handler\n- `iconify` - Fetch icons with Iconify API, purely client-side\n\n`server` by default; `iconify` when `ssr: false`",
            "enum": [
              "server",
              "iconify"
            ],
            "tags": [
              "@studioIcon material-symbols:cloud"
            ],
            "type": "\"server\" | \"iconify\" | undefined",
            "id": "#appConfig/icon/provider"
          },
          "iconifyApiEndpoint": {
            "title": "Iconify API Endpoint URL",
            "description": "Define a custom Iconify API endpoint URL. Useful if you want to use a self-hosted Iconify API. Learn more: https://iconify.design/docs/api.",
            "tags": [
              "@studioIcon material-symbols:api"
            ],
            "id": "#appConfig/icon/iconifyApiEndpoint",
            "default": "https://api.iconify.design",
            "type": "string"
          },
          "fallbackToApi": {
            "title": "Fallback to Iconify API",
            "description": "Fallback to Iconify API if server provider fails to found the collection.",
            "tags": [
              "@studioIcon material-symbols:public"
            ],
            "enum": [
              true,
              false,
              "server-only",
              "client-only"
            ],
            "type": "boolean | \"server-only\" | \"client-only\"",
            "id": "#appConfig/icon/fallbackToApi",
            "default": true
          },
          "localApiEndpoint": {
            "title": "Local API Endpoint Path",
            "description": "Define a custom path for the local API endpoint.",
            "tags": [
              "@studioIcon material-symbols:api"
            ],
            "id": "#appConfig/icon/localApiEndpoint",
            "default": "/api/_nuxt_icon",
            "type": "string"
          },
          "fetchTimeout": {
            "title": "Fetch Timeout",
            "description": "Set the timeout for fetching icons.",
            "tags": [
              "@studioIcon material-symbols:timer"
            ],
            "id": "#appConfig/icon/fetchTimeout",
            "default": 1500,
            "type": "number"
          },
          "customize": {
            "title": "Customize callback",
            "description": "Customize icon content (replace stroke-width, colors, etc...).",
            "tags": [
              "@studioIcon material-symbols:edit"
            ],
            "type": "IconifyIconCustomizeCallback",
            "id": "#appConfig/icon/customize"
          }
        },
        "type": "object",
        "default": {
          "size": {},
          "class": "",
          "attrs": {
            "aria-hidden": true
          },
          "mode": "css",
          "aliases": {},
          "cssSelectorPrefix": "i-",
          "cssLayer": {},
          "cssWherePseudo": true,
          "collections": null,
          "customCollections": null,
          "iconifyApiEndpoint": "https://api.iconify.design",
          "fallbackToApi": true,
          "localApiEndpoint": "/api/_nuxt_icon",
          "fetchTimeout": 1500
        }
      }
    },
    "type": "object",
    "default": {
      "icon": {
        "size": {},
        "class": "",
        "attrs": {
          "aria-hidden": true
        },
        "mode": "css",
        "aliases": {},
        "cssSelectorPrefix": "i-",
        "cssLayer": {},
        "cssWherePseudo": true,
        "collections": null,
        "customCollections": null,
        "iconifyApiEndpoint": "https://api.iconify.design",
        "fallbackToApi": true,
        "localApiEndpoint": "/api/_nuxt_icon",
        "fetchTimeout": 1500
      }
    }
  },
  "default": {
    "icon": {
      "size": {},
      "class": "",
      "attrs": {
        "aria-hidden": true
      },
      "mode": "css",
      "aliases": {},
      "cssSelectorPrefix": "i-",
      "cssLayer": {},
      "cssWherePseudo": true,
      "collections": null,
      "customCollections": null,
      "iconifyApiEndpoint": "https://api.iconify.design",
      "fallbackToApi": true,
      "localApiEndpoint": "/api/_nuxt_icon",
      "fetchTimeout": 1500
    }
  }
}